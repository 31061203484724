<template>
  <div>
    <div id="title">
      <span class="position">
        First Officer B737
      </span>
      <br />
      <span class="name">
        Bram Koene
      </span>
      <br />
      <span class="icons">
        <a href="https://github.com/minestarnl" class="github">
          <svg
            enable-background="new -1163 1657.697 56.693 56.693"
            height="56.693px"
            id="Layer_1"
            version="1.1"
            viewBox="-1163 1657.697 56.693 56.693"
            width="56.693px"
            xml:space="preserve"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
          >
            <g>
              <path
                clip-rule="evenodd"
                d="M-1134.6598,1662.9163c-13.601,0-24.63,11.0267-24.63,24.6299   c0,10.8821,7.0573,20.1144,16.8435,23.3713c1.2308,0.2279,1.6829-0.5345,1.6829-1.1849c0-0.587-0.0227-2.5276-0.0334-4.5857   c-6.8521,1.4901-8.2979-2.906-8.2979-2.906c-1.1205-2.8467-2.7347-3.6039-2.7347-3.6039   c-2.2349-1.5287,0.1685-1.4972,0.1685-1.4972c2.473,0.1737,3.7755,2.5385,3.7755,2.5385c2.1967,3.7651,5.7618,2.6765,7.1675,2.0472   c0.2211-1.5917,0.8591-2.6786,1.5637-3.2936c-5.4707-0.6226-11.2218-2.7347-11.2218-12.1722c0-2.6888,0.9623-4.8861,2.538-6.611   c-0.2557-0.6206-1.0989-3.1255,0.2386-6.5183c0,0,2.0684-0.6616,6.7747,2.525c1.9648-0.5458,4.0719-0.8195,6.165-0.829   c2.093,0.0095,4.2017,0.2832,6.17,0.829c4.7012-3.1866,6.7665-2.525,6.7665-2.525c1.3406,3.3928,0.4974,5.8977,0.2417,6.5183   c1.5793,1.7249,2.5348,3.9221,2.5348,6.611c0,9.4602-5.7618,11.5428-11.2465,12.1527c0.8834,0.7644,1.6704,2.2632,1.6704,4.561   c0,3.2955-0.0282,5.9479-0.0282,6.7592c0,0.6556,0.4432,1.4236,1.6915,1.1818c9.7812-3.2605,16.8296-12.4896,16.8296-23.3682   C-1110.0299,1673.943-1121.0574,1662.9163-1134.6598,1662.9163z"
                fill-rule="evenodd"
              />
              <path
                d="M-1149.9611,1698.2793c-0.0542,0.1227-0.2469,0.1593-0.4222,0.0753c-0.1788-0.0804-0.2788-0.2473-0.2211-0.37   c0.053-0.126,0.2457-0.161,0.4242-0.0769C-1150.0013,1697.9882-1149.8993,1698.1566-1149.9611,1698.2793L-1149.9611,1698.2793z    M-1150.2642,1698.0547"
              />
              <path
                d="M-1148.9634,1699.3922c-0.1174,0.1086-0.3473,0.0581-0.5031-0.1139c-0.1613-0.1718-0.1912-0.4016-0.072-0.5118   c0.1211-0.1088,0.3438-0.0579,0.505,0.1139C-1148.8721,1699.0541-1148.8407,1699.2819-1148.9634,1699.3922L-1148.9634,1699.3922z    M-1149.1984,1699.14"
              />
              <path
                d="M-1147.9922,1700.8105c-0.151,0.1051-0.3979,0.0067-0.5505-0.2123c-0.151-0.2191-0.151-0.4819,0.0035-0.5872   c0.1526-0.1051,0.396-0.0104,0.5505,0.2068C-1147.8381,1700.4406-1147.8381,1700.7034-1147.9922,1700.8105L-1147.9922,1700.8105z    M-1147.9922,1700.8105"
              />
              <path
                d="M-1146.6619,1702.1812c-0.1351,0.1489-0.4227,0.1086-0.6329-0.0945c-0.2155-0.1984-0.2753-0.4803-0.1403-0.6293   c0.1371-0.149,0.4263-0.1072,0.6381,0.0944C-1146.5831,1701.7501-1146.5182,1702.0337-1146.6619,1702.1812L-1146.6619,1702.1812z    M-1146.6619,1702.1812"
              />
              <path
                d="M-1144.8265,1702.9769c-0.0597,0.1927-0.3365,0.2804-0.6154,0.1984c-0.2788-0.0845-0.4608-0.3103-0.4047-0.5051   c0.0577-0.1943,0.3361-0.2855,0.6169-0.1979C-1144.9512,1702.5563-1144.7688,1702.7805-1144.8265,1702.9769L-1144.8265,1702.9769z    M-1144.8265,1702.9769"
              />
              <path
                d="M-1142.8107,1703.1243c0.0067,0.2031-0.2299,0.3716-0.5226,0.3752c-0.2944,0.0067-0.533-0.1577-0.5361-0.3577   c0-0.2052,0.2313-0.3717,0.5258-0.3768C-1143.0509,1702.7594-1142.8107,1702.9227-1142.8107,1703.1243L-1142.8107,1703.1243z    M-1142.8107,1703.1243"
              />
              <path
                d="M-1140.9351,1702.8052c0.035,0.198-0.1686,0.4015-0.4594,0.4557c-0.2859,0.0526-0.5504-0.0701-0.587-0.2665   c-0.0354-0.2031,0.1716-0.4066,0.4573-0.4592C-1141.233,1702.4846-1140.9722,1702.6036-1140.9351,1702.8052L-1140.9351,1702.8052z    M-1140.9351,1702.8052"
              />
            </g></svg
        ></a>

        <a href="https://www.instagram.com/_bramk_/" class="instagram">
          <svg
            height="56.7px"
            id="Layer_1"
            style="enable-background:new 0 0 56.7 56.7;"
            version="1.1"
            viewBox="0 0 56.7 56.7"
            width="56.7px"
            xml:space="preserve"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
          >
            <g>
              <circle cx="28.1" cy="30" r="4.4" />
              <path
                d="M33.6,19.2h-11c-1.6,0-3,0.5-3.9,1.4c-0.9,0.9-1.4,2.3-1.4,3.9v11c0,1.6,0.5,3,1.5,4c1,0.9,2.3,1.4,3.9,1.4h10.9   c1.6,0,3-0.5,3.9-1.4c1-0.9,1.5-2.3,1.5-3.9v-11c0-1.6-0.5-2.9-1.4-3.9C36.6,19.7,35.3,19.2,33.6,19.2z M28.1,36.8   c-3.8,0-6.8-3.1-6.8-6.8c0-3.8,3.1-6.8,6.8-6.8S35,26.2,35,30C35,33.8,31.9,36.8,28.1,36.8z M35.2,24.5c-0.9,0-1.6-0.7-1.6-1.6   s0.7-1.6,1.6-1.6s1.6,0.7,1.6,1.6S36.1,24.5,35.2,24.5z"
              />
              <path
                d="M28.3,5.2c-13.6,0-24.6,11-24.6,24.6c0,13.6,11,24.6,24.6,24.6c13.6,0,24.6-11,24.6-24.6C53,16.2,41.9,5.2,28.3,5.2z    M41.4,35.6c0,2.3-0.8,4.3-2.2,5.7c-1.4,1.4-3.4,2.1-5.6,2.1H22.7c-2.2,0-4.2-0.7-5.6-2.1c-1.5-1.4-2.2-3.4-2.2-5.7v-11   c0-4.6,3.1-7.8,7.8-7.8h11c2.3,0,4.2,0.8,5.6,2.2c1.4,1.4,2.1,3.3,2.1,5.6V35.6z"
              />
            </g></svg
        ></a>

        <a
          href="https://www.linkedin.com/in/bram-koene-6b31a0139/"
          class="linkedin"
          ><svg
            enable-background="new 0 0 56.693 56.693"
            height="56.693px"
            id="Layer_1"
            version="1.1"
            viewBox="0 0 56.693 56.693"
            width="56.693px"
            xml:space="preserve"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
          >
            <g>
              <path
                d="M28.347,5.155c-13.6,0-24.625,11.025-24.625,24.625c0,13.602,11.025,24.625,24.625,24.625   c13.598,0,24.623-11.023,24.623-24.625C52.97,16.181,41.944,5.155,28.347,5.155z M42.062,41.741c0,1.096-0.91,1.982-2.031,1.982   H16.613c-1.123,0-2.031-0.887-2.031-1.982V18.052c0-1.094,0.908-1.982,2.031-1.982H40.03c1.121,0,2.031,0.889,2.031,1.982V41.741z"
              />
              <path
                d="M33.099,26.441c-2.201,0-3.188,1.209-3.74,2.061v0.041h-0.027c0.01-0.012,0.02-0.027,0.027-0.041v-1.768h-4.15   c0.055,1.17,0,12.484,0,12.484h4.15v-6.973c0-0.375,0.027-0.744,0.137-1.012c0.301-0.744,0.984-1.52,2.129-1.52   c1.504,0,2.104,1.146,2.104,2.824v6.68h4.15V32.06C37.878,28.224,35.829,26.441,33.099,26.441z"
              />
              <path
                d="M20.864,20.712c-1.419,0-2.349,0.934-2.349,2.159c0,1.197,0.9,2.158,2.294,2.158h0.027c1.447,0,2.348-0.961,2.348-2.158   C23.157,21.646,22.284,20.712,20.864,20.712z"
              />
              <rect height="12.484" width="4.151" x="18.762" y="26.734" />
            </g></svg
        ></a>

        <a href="mailto:bramkoene@gmail.com?subject=Mail from Site" class="mail"
          ><svg
            height="56.6934px"
            id="Layer_1"
            style="enable-background:new 0 0 56.6934 56.6934;"
            version="1.1"
            viewBox="0 0 56.6934 56.6934"
            width="56.6934px"
            xml:space="preserve"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
          >
            <polygon
              points="29.3938,31.666 39.309,38.5604 39.309,24.8362 34.9235,27.8569 "
            />
            <path
              d="M28.3472,3.9229c-13.5996,0-24.625,11.0234-24.625,24.623c0,13.5997,11.0254,24.625,24.625,24.625  c13.5986,0,24.624-11.0253,24.624-24.625C52.9712,14.9463,41.9458,3.9229,28.3472,3.9229z M39.9141,39.7094v0.0007H16.7774h-0.8704  c-1.3447,0-2.4451-1.1003-2.4451-2.4451V20.5627c0-1.3448,1.1003-2.4451,2.4451-2.4451l0.0096,0.0072l-0.0096-0.0078h24.9518  c1.3447,0,2.445,1.1002,2.445,2.4449v0.7087l0.0007-0.0005l-0.0007,0.0007l0.004,15.9963  C43.2494,39.0451,42.479,39.6797,39.9141,39.7094z"
            />
            <path
              d="M28.3421,32.2622c-0.1196,0-0.2391-0.0359-0.3419-0.1073l-0.1786-0.1244l-10.1835,7.0811h21.4153l-10.1863-7.0829  C28.8671,32.0287,28.4607,32.2622,28.3421,32.2622z"
            />
            <polygon points="40.858,18.1176 40.8587,18.117 40.8565,18.1187 " />
            <polygon points="39.037,18.7154 17.7191,18.7154 28.3432,26.5924 " />
            <polygon
              points="27.2877,31.659 17.382,24.8359 17.3764,38.5647 27.2976,31.666 "
            /></svg
        ></a>
      </span>
    </div>

    <div id="particles-js"></div>
  </div>
</template>

<script>
import "particles.js";

export default {
  name: "App",

  components: {},

  data: () => ({
    //
  }),

  mounted: () => {
    console.log(window.particlesJS);
    window.particlesJS(
      "particles-js",
      {
        particles: {
          number: {
            value: 161,
            density: {
              enable: true,
              value_area: 800,
            },
          },
          color: {
            value: "#ffffff",
          },
          shape: {
            type: "circle",
            stroke: {
              width: 3,
              color: "#000000",
            },
            polygon: {
              nb_sides: 6,
            },
            image: {
              src: "img/github.svg",
              width: 100,
              height: 100,
            },
          },
          opacity: {
            value: 0.5,
            random: false,
            anim: {
              enable: true,
              speed: 1,
              opacity_min: 0.1,
              sync: false,
            },
          },
          size: {
            value: 4,
            random: true,
            anim: {
              enable: false,
              speed: 40,
              size_min: 0.1,
              sync: false,
            },
          },
          line_linked: {
            enable: false,
            distance: 150,
            color: "#ffffff",
            opacity: 0.4,
            width: 1,
          },
          move: {
            enable: true,
            speed: 2,
            direction: "top",
            random: true,
            straight: false,
            out_mode: "out",
            bounce: false,
            attract: {
              enable: false,
              rotateX: 600,
              rotateY: 1200,
            },
          },
        },
        interactivity: {
          detect_on: "canvas",
          events: {
            onhover: {
              enable: false,
              mode: "repulse",
            },
            onclick: {
              enable: true,
              mode: "push",
            },
            resize: true,
          },
          modes: {
            grab: {
              distance: 400,
              line_linked: {
                opacity: 1,
              },
            },
            bubble: {
              distance: 400,
              size: 40,
              duration: 2,
              opacity: 8,
              speed: 3,
            },
            repulse: {
              distance: 200,
              duration: 0.4,
            },
            push: {
              particles_nb: 4,
            },
            remove: {
              particles_nb: 2,
            },
          },
        },
        retina_detect: true,
      },
      function() {
        console.log("callback - particles.js config loaded");
      }
    );
  },
};
</script>

<style lang="scss">
@import "../node_modules/typeface-roboto/index.css";
#particles-js {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: #1b1b1b;
}

#title {
  z-index: 9;
  color: #dfdfdf;
  margin: auto;
  position: absolute;
  top: 40%;
  left: 50%;
  width: 70%;
  transform: translate(-50%, -50%);
  font-family: roboto;
  text-align: center;
}

.position {
  font-size: 130%;
}

.name {
  font-size: 270%;
}

svg {
  width: 40px;
  height: 40px;
}

.instagram {
  fill: #dfdfdf;
  margin-right: 15px;
}

.linkedin {
  fill: #dfdfdf;
  margin-right: 15px;
}

.mail {
  fill: #dfdfdf;
}

.github {
  fill: #dfdfdf;
  margin-right: 15px;
}
</style>
